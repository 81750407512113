import { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { auth } from "../../../Utils/Auth.js";
import L, { Layer } from "leaflet";
import "./styles.css";
import "leaflet/dist/leaflet.css";
import LL from "./dist/leaflet-measure.js";
import "./dist/main.css";
import moment from "moment";
import Layout from "./Layout.js";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

const FarmerFarmAreaMaps = () => {
	let { farmerID } = useParams();

	const [farms, setFarms] = useState([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);

	useEffect(() => {
		getSingleFarmMappings();
	}, []);

	useEffect(() => {
		if (farms.length > 0) {
			try {
				const farm_ = farms[0];

				var wmsLayer = L.tileLayer.wms(
					"http://ows.mundialis.de/services/service?",
					{
						layers: "TOPO-OSM-WMS",
						minZoom: 14,
						maxZoom: 19,
					}
				);

				var osmLayer = L.tileLayer(
					"https://tile.openstreetmap.org/{z}/{x}/{y}.png",
					{
						minZoom: 14,
						maxZoom: 19,
						attribution:
							'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
					}
				);

				var satelliteLayer = L.tileLayer(
					"//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
					{
						minZoom: 14,
						maxZoom: 19,
						attribution:
							"&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community",
					}
				);

				console.log(
					"Farm location >>> ",
					farm_.location.coordinates[1]
				);
				var options = {
					center: [
						farm_.location.coordinates[1],
						farm_.location.coordinates[0],
					],
					zoom: 15,
					layers: [satelliteLayer],
					measureControl: true,
				};

				var map = L.map("map", options);

				var baseMaps = {
					"Satellite View": satelliteLayer,
					"OpenStreetMap View": osmLayer,
					"OsmWmsMap View": wmsLayer,
				};

				var layerControl = L.control.layers(baseMaps).addTo(map);

				// var measureControl = new L.Control.Measure(options);
				// measureControl.addTo(map);

				var farm_area = farms.map((farm, index) => {
					var geojsonFeature = {
						type: "Feature",
						properties: {
							name: farm.name,
							address: farm.address,
							area: farm.farmland_area.area_in_sqm,
							created_at: farm.created_at,
						},
						geometry: farm.farm_area,
					};

					return geojsonFeature;
				});

				console.log("Farm area mappings >>> ", farm_area);

				var polygon = L.geoJSON(farm_area, {
					style: { fill: false },
					onEachFeature: onEachFeature,
				}).addTo(map);
				var bounds = polygon.getBounds();

				// console.log("Farm area bounds >>> ", bounds);

				map.flyToBounds(bounds);

				return () => {
					map.remove();
				};
			} catch (error) {
				console.log("Error >>> ", error);
			}
		}
	}, [farms]);

	function onEachFeature(feature, layer) {
		// does this feature have a property named popupContent?
		if (feature.properties) {
			var { name, area, address, created_at } = feature.properties;
			var pContent = `<p>Name: ${name}<br />Address: ${address}<br />Area: ${area} sqm<br />Date created: ${moment(
				created_at
			).format("Do MMM, YYYY")}</p>`;
			layer.bindPopup(pContent);
		}
	}

	const getSingleFarmMappings = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}farmers/${farmerID}`,
				{ headers: auth.tokenHeader }
			);

			console.log("process.env.REACT_APP_API_URL >>> ", data.data);

			setFarms(data.data.farms);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="farmers">
			<div id="map"></div>
		</Layout>
	);
};

export default FarmerFarmAreaMaps;
