import { useState, useEffect, Children } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import "./styles.css";
import React from 'react';
import logo from '../../../Assets/logo.png';

const Layout = (props) => {
	return (
		<div>
			<div class="topnav">
				<Link to="/dashboard">
					<img src={logo} className="logo" />
				</Link>
				<Link class={props.active == "overview" ? "active" : ""} to="/dashboard">
					Overview
				</Link>
				<Link class={props.active == "agronomists" ? "active" : ""} to="/agronomists">
					Agronomists
				</Link>
				<Link class={props.active == "farmers" ? "active" : ""} to="/farmers">
					Farmers
				</Link>
				<Link class={props.active == "farms" ? "active" : ""} to="/farms">Farms</Link>
				<Link class={props.active == "organisations" ? "active" : ""} to="/organisations">Organisations</Link>
			</div>
			<div>{props.children}</div>
		</div>
	);
};

export default Layout;
