import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import "./styles.css";
import Layout from "./Layout";

const SingleOrg = () => {
	const {orgID} = useParams()
	useEffect(() => {
		getOrg();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [org, setOrg] = useState(null);
	const [farmers, setFarmers] = useState([]);
	const [resources, setResources] = useState([]);

	const getOrg = async () => {
		try {

			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}orgs/${orgID}`,
				{ headers: auth.tokenHeader }
			);

			console.log(
				"process.env.REACT_APP_API_URL >>> ",
				data.data.org
			);

			setOrg(data.data.organisation);
			setFarmers(data.data.farmers);
			setResources(data.data.resources);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="organisations">
			<div id="container">
				<section>
				<h5>Farmers Joined</h5>
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Name</td>
							<td>Email</td>
							<td>Gender</td>
							{/* <td>Actions</td> */}
						</tr>
					</thead>
					<tbody>
						{farmers.map((farmer, index) => (
							<tr key={farmer.id}>
								<td>{index + 1}</td>
								<td>
									{farmer.first_name} {farmer.last_name}
								</td>
								<td>{farmer.email}</td>
								<td>{farmer.gender}</td>
							</tr>
						))}
					</tbody>
				</table>
				</section>
				<br/>
				<br/>
				<br/>
				<section>
				<h5>Resources</h5>
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Name</td>
							<td>Type</td>
							<td>Qty</td>
							<td>Unit</td>
						</tr>
					</thead>
					<tbody>
						{resources.map((resource, index) => (
							<tr key={resource.id}>
								<td>{index + 1}</td>
								<td>
									{resource.name}
								</td>
								<td>{resource.type}</td>
								<td>{resource.qty}</td>
								<td>{resource.unit}</td>
							</tr>
						))}
					</tbody>
				</table>
				</section>
			</div>
		</Layout>
	);
};

export default SingleOrg;
