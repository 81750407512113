import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import "./styles.css";
import Layout from "./Layout";

const Agronomists = () => {
	useEffect(() => {
		getAgronomists();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [agronomists, setFarmers] = useState([]);

	const getAgronomists = async () => {
		try {
			// const {data} = await axios.get(
			// 	`${process.env.REACT_APP_API_URL}agronomists?page=${page}&limit=${limit}`
			// );

			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}agro?page=${page}&limit=${limit}`,
				{ headers: auth.tokenHeader }
			);

			console.log(
				"process.env.REACT_APP_API_URL >>> ",
				data.data.agronomists
			);

			setFarmers(data.data.agronomists);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="agronomists">
			<div id="container">
				{/* <h3>Agronomists</h3> */}
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Name</td>
							<td>Email</td>
							<td>Farmers captured</td>
							<td>Actions</td>
						</tr>
					</thead>
					<tbody>
						{agronomists.map((agronomist, index) => (
							<tr key={agronomist.id}>
								<td>{index + 1}</td>
								<td>
									{agronomist.first_name}{" "}
									{agronomist.last_name}
								</td>
								<td>{agronomist.email}</td>
								<td>{agronomist.agronomist_farmers}</td>
								<td>
									{/* <button>View</button> */}
									<Link to={`${agronomist.id}/farms`}>
										View Farms
									</Link>{" "}
									&nbsp;
									<Link to={`${agronomist.id}/farms/mapping`}>
										View Mapping
									</Link>{" "}
									&nbsp;
									<Link to={`${agronomist.id}/farmers`}>
										View Farmers
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Layout>
	);
};

export default Agronomists;
