import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import "./styles.css";
import Layout from "./Layout";

const Organisations = () => {
	useEffect(() => {
		getOrgs();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [orgs, setOrgs] = useState([]);

	const getOrgs = async () => {
		try {
			// const {data} = await axios.get(
			// 	`${process.env.REACT_APP_API_URL}orgs?page=${page}&limit=${limit}`
			// );

			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}orgs?page=${page}&limit=${limit}`,
				{ headers: auth.tokenHeader }
			);

			console.log(
				"process.env.REACT_APP_API_URL >>> ",
				data.data.orgs
			);

			setOrgs(data.data.orgs);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="organisations">
			<div id="container">
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Name</td>
							<td>Email</td>
							<td>Is Association</td>
							<td>Status</td>
							<td>Actions</td>
						</tr>
					</thead>
					<tbody>
						{orgs.map((org, index) => (
							<tr key={org.id}>
								<td>{index + 1}</td>
								<td>
									{org.name}
								</td>
								<td>{org.email}</td>
								<td>{org.is_association ? "Yes" : "No"}</td>
								<td>{org.is_verified ? "Verified" : "Not verified"}</td>
								<td>
									<Link to={`${org._id}`}>
										View Details
									</Link>{" "}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Layout>
	);
};

export default Organisations;
