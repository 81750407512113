import { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect, useNavigate } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import logo from "../../../Assets/logo.png";

import "./styles.css";

const Login = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isPending, setIsPending] = useState(false);

	const handleSubmit = async () => {
		try {
			const payload = {
				email,
				password,
			};
			console.log(payload);

			const { data } = await axios.post(
				`${process.env.REACT_APP_API_URL}auth/login`,
				payload
			);

			console.log(data.data.token);

			localStorage.setItem("@token", data.data.token);
			navigate("/dashboard");
		} catch (e) {
			console.log(e?.response?.data?.message);
			alert(e?.response?.data?.message);
		}
	};

	return (
		<Box
			sx={{
				display: { xs: "flex", lg: "grid" },
				flexDirection: "column",
				gridTemplateColumns: "1fr 1fr",
				minHeight: "100%",
			}}
			id="container"
		>
			<Box
				sx={{
					display: "flex",
					flex: "1 1 auto",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flex: "1 1 auto",
						justifyContent: "center",
						p: 3,
					}}
				>
					<Box sx={{ maxWidth: "450px", width: "100%" }}>
						<Box
							component={Link}
							// href={paths.home}
							sx={{ display: "inline-block", fontSize: 0 }}
						>
							<img src={logo} className="logo" />
						</Box>

						<br/>
						<br/>
						<br/>

						<Stack spacing={4}>
							<Stack spacing={1}>
								<Typography variant="h5">Sign in</Typography>
							</Stack>

							<div>
								<label htmlFor="uname">
									<b>Email</b>
								</label>
								<input
									type="text"
									placeholder="Enter Username"
									name="uname"
									required
									onChange={(e) => setEmail(e.target.value)}
								/>

								<br />

								<label htmlFor="psw">
									<b>Password</b>
								</label>
								<input
									type="password"
									placeholder="Enter Password"
									name="psw"
									required
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>

								<button
									onClick={handleSubmit}
									disabled={!email || !password}
								>
									Login
								</button>
							</div>
						</Stack>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					alignItems: "center",
					background:
						"radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)",
					color: "var(--mui-palette-common-white)",
					display: { xs: "none", lg: "flex" },
					justifyContent: "center",
					p: 3,
				}}
			>
				<Stack spacing={3}>
					<Stack spacing={1}>
						<Typography
							color="inherit"
							sx={{
								fontSize: "24px",
								lineHeight: "32px",
								textAlign: "center",
							}}
							variant="h1"
						>
							Welcome to{" "}
							<Box component="span" sx={{ color: "#15b79e" }}>
								AVAC ADMIN
							</Box>
						</Typography>
						{/* <Typography align="center" variant="subtitle1">
							A professional website to manage your farm
							resources.
						</Typography> */}
					</Stack>
				</Stack>
			</Box>
		</Box>
	);
};

export default Login;
