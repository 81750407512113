import { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route, useParams, Link } from "react-router-dom";
import "./styles.css";
import { auth } from "../../../Utils/Auth";

const FarmerFarms = () => {
	let { farmerID } = useParams();

	console.log("useParams >>> ", farmerID);

	useEffect(() => {
		getFarmers();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [farm, setFarms] = useState([]);

	const getFarmers = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}farmers/${farmerID}/farms?page=${page}&limit=${limit}`,
				{ headers: auth.tokenHeader }
			);

			console.log("process.env.REACT_APP_API_URL >>> ", data.data);

			setFarms(data.data.farms);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<div id="container">
			<h3>
				{farm.length > 0
					? `${farm[0].farmer.first_name} ${farm[0].farmer.last_name}`
					: "Farmer"}{" "}
				Farms
			</h3>
			<table id="customers">
				<thead>
					<tr>
						<td>S/N</td>
						<td>Name</td>
						<td>State</td>
						<td>Town</td>
						<td>Description</td>
						<td>Action</td>
					</tr>
				</thead>
				<tbody>
					{farm.map((farm, index) => (
						<tr key={farm.id}>
							<td>{index + 1}</td>
							{/* <td>
								{farm.farmer.first_name} {farm.farmer.last_name}
							</td> */}
							<td>{farm.name}</td>
							<td>{farm.state}</td>
							<td>{farm.town}</td>
							<td>{farm.description}</td>
							<td>
								{/* <button>View</button> */}
								{/* <Link to={farm.id}>View</Link> */}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default FarmerFarms;
