import { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route, useParams, Link } from "react-router-dom";
import "./styles.css";
import { auth } from "../../../Utils/Auth";
import Layout from "./Layout";

const AllFarms = () => {
	let { farmerID } = useParams();

	console.log("useParams >>> ", farmerID);

	useEffect(() => {
		getAllFarms();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [farm, setFarms] = useState([]);

	const getAllFarms = async () => {
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}farms?page=${page}&limit=${limit}`,
				{ headers: auth.tokenHeader }
			);

			setFarms(data.data.farms);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="farms">
			<div id="container">
				<h3>All Farms</h3>
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Name</td>
							<td>State</td>
							<td>Town</td>
							<td>Description</td>
							<td>Action</td>
						</tr>
					</thead>
					<tbody>
						{farm.map((farm, index) => (
							<tr key={farm.id}>
								<td>{index + 1}</td>
								{/* <td>
								{farm.farmer.first_name} {farm.farmer.last_name}
							</td> */}
								<td>{farm.name}</td>
								<td>{farm.state}</td>
								<td>{farm.town}</td>
								<td>{farm.description}</td>
								<td>
									{/* <button>View</button> */}
									{/* <Link to={farm.id}>View</Link> */}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Layout>
	);
};

export default AllFarms;
