import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../Components/Screens/Home";
import AgroFarmers from "../Components/Screens/Home/agroFarmers";
import FarmerFarms from "../Components/Screens/Home/farmerFarms";
import AgroFarmAreaMaps from "../Components/Screens/Home/agroFarmAreaMaps";
import Agronomists from "../Components/Screens/Home/Agronomists";
import Farmers from "../Components/Screens/Home/Farmers";
import AllFarms from "../Components/Screens/Home/AllFarms";
import FarmerFarmAreaMaps from "../Components/Screens/Home/FarmerFarmAreaMaps";
import Login from "../Components/Screens/Home/Login";
import AgroFarms from "../Components/Screens/Home/AgroFarms";
import FarmAreaMap from "../Components/Screens/Home/FarmAreaMap";
import Organisations from "../Components/Screens/Home/Organisations";
import SingleOrg from "../Components/Screens/Home/SingleOrg";

const CRouter = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
	},
	{
		path: "/dashboard",
		element: <Home />,
	},
	{
		path: "/agronomists",
		element: <Agronomists />,
	},
    {
		path: "/agronomists/:agroID/farmers",
		element: <AgroFarmers />,
	},
    {
		path: "/agronomists/:agroID/farms",
		element: <AgroFarms />,
	},
    {
		path: "/agronomists/:agroID/farms/mapping",
		element: <AgroFarmAreaMaps />,
	},
	{
		path: "/farmers",
		element: <Farmers />,
	},
    {
		path: "/farmers/:farmerID",
		element: <FarmerFarms />,
	},
    {
		path: "/farmers/:farmerID/mapping",
		element: <FarmerFarmAreaMaps />,
	},
	{
		path: "/farms",
		element: <AllFarms />,
	},
	{
		path: "/farms/:farmID/mapping",
		element: <FarmAreaMap />,
	},
	{
		path: "/organisations",
		element: <Organisations />,
	},
	{
		path: "/organisations/:orgID",
		element: <SingleOrg />,
	},
]);

export default CRouter;
