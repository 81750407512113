import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { auth } from "../../../Utils/Auth";
import "./styles.css";
import Layout from "./Layout";

const Home = () => {
	useEffect(() => {
		getOverview();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [overview, setOverview] = useState([]);

	const getOverview = async () => {
		try {
			console.log(
				"Auuuth >>> ",
				localStorage.getItem("@token"),
				auth.tokenHeader
			);

			const { data } = await axios.get(
				`${process.env.REACT_APP_API_URL}overview/totals`,
				{ headers: auth.tokenHeader }
			);

			setOverview(data.data);
		} catch (error) {
			console.log("Error >>> ", error.message);
		}
	};

	return (
		<Layout active="overview">
			<div className="container overview">
				{/* <h3>Overview</h3> */}

				<div className="hr-box">
					<div className="card">
						<div className="card-box">
							<h5>
								<b>
									<Link to={`/agronomists`}>
										Agronomists
									</Link>
								</b>
							</h5>
							<h3>{overview?.total_agronomists ?? 0}</h3>
						</div>
					</div>
					<div className="card">
						<div className="card-box">
							<h5>
								<b>
									<Link to={`/farmers`}>Farmers</Link>
								</b>
							</h5>
							<h3>{overview?.total_farmers ?? 0}</h3>
						</div>
					</div>
					<div className="card">
						<div className="card-box">
							<h5>
								<b>
									<Link to={`/farms`}>Farms</Link>
								</b>
							</h5>
							<h3>{overview?.total_farms ?? 0}</h3>
						</div>
					</div>
					<div className="card">
						<div className="card-box">
							<h5>
								<b>Subscriptions</b>
							</h5>
							<h3>₦ {overview?.total_subscription_amount}</h3>
						</div>
					</div>
				</div>

				<br />

				<h4>Transactions</h4>
				<table id="customers">
					<thead>
						<tr>
							<td>S/N</td>
							<td>Amount</td>
							<td>Status</td>
						</tr>
					</thead>
					<tbody>
						{overview?.recent_transactions?.map(
							(transaction, index) => (
								<tr key={transaction.id}>
									<td>{index + 1}</td>
									<td>{transaction.amount}</td>
									<td>{transaction.status}</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</div>
		</Layout>
	);
};

export default Home;
