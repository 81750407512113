import { useState, useEffect } from "react";
import { Routes, Route, useParams, Link, useLocation } from "react-router-dom";
import L from "leaflet";
import "./styles.css";
import "leaflet/dist/leaflet.css";
import LL from "./dist/leaflet-measure.js";
import "./dist/main.css";
import moment from "moment";
import Layout from "./Layout.js";

const FarmAreaMap = () => {
	// let { farm } = useParams();

	let { state } = useLocation();

	const farm = state?.farm;

	useEffect(() => {
		try {
			var wmsLayer = L.tileLayer.wms(
				"http://ows.mundialis.de/services/service?",
				{
					layers: "TOPO-OSM-WMS",
					minZoom: 14,
					maxZoom: 19,
				}
			);

			var osmLayer = L.tileLayer(
				"https://tile.openstreetmap.org/{z}/{x}/{y}.png",
				{
					minZoom: 14,
					maxZoom: 19,
					attribution:
						'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				}
			);

			var satelliteLayer = L.tileLayer(
				"//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
				{
					minZoom: 14,
					maxZoom: 19,
					attribution:
						"&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community",
				}
			);

			console.log("Farm >>> ", farm);

			var options = {
				center: [
					farm.location.coordinates[1],
					farm.location.coordinates[0],
				],
				zoom: 15,
				layers: [satelliteLayer],
				measureControl: true,
			};

			var map = L.map("map", options);

			var baseMaps = {
				"Satellite View": satelliteLayer,
				"OpenStreetMap View": osmLayer,
				"OsmWmsMap View": wmsLayer,
			};

			var layerControl = L.control.layers(baseMaps).addTo(map);

			let geojsonFeature = {
				type: "Feature",
				properties: {
					name: farm.name,
					address: farm.address,
					area: farm.farmland_area.area_in_sqm,
					created_at: farm.created_at,
				},
				geometry: farm.farm_area,
			};

			console.log("Farm area mappings >>> ", geojsonFeature);

			var polygon = L.geoJSON(geojsonFeature, {
				style: { fill: false },
				onEachFeature: onEachFeature,
			}).addTo(map);
			var bounds = polygon.getBounds();

			// console.log("Farm area bounds >>> ", bounds);

			map.flyToBounds(bounds);

			return () => {
				map.remove();
			};
		} catch (error) {}
	}, []);

	function onEachFeature(feature, layer) {
		// does this feature have a property named popupContent?
		if (feature.properties) {
			var { name, area, address, created_at } = feature.properties;
			var pContent = `<p>Name: ${name}<br />Address: ${address}<br />Area: ${area} sqm<br />Date created: ${moment(
				created_at
			).format("Do MMM, YYYY")}</p>`;
			layer.bindPopup(pContent);
		}
	}
	``;

	return (
		<Layout active="farms">
			<div id="map"></div>
		</Layout>
	);
};

export default FarmAreaMap;
